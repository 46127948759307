.html-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity       : 0.45;
    background: $dark-gray;
}

.html-modal-holder {
      position: relative;
      top: 0;
      left: 0;
      width:100%;
      z-index: 2000;
      background: linear-gradient($background-black, $background-states-black);
      box-shadow: 4px 4px 7px 0 rgba(50, 50, 50, 0.75);
}

.white-content {
    .html-modal-holder
    {
      position: relative;
      top: 0;
      left: 0;
      width:100%;
      z-index: 2000;
      background:  $light-bg;
      box-shadow: 4px 4px 7px 0 rgba(50, 50, 50, 0.75);
    }
}
