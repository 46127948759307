.parent-table-container {
    overflow: auto;
    height  : calc(100%);
}

#regularMap {
    position: relative;
    width   : 100vw;
}

.gse-summary-card-header {
    min-height: 75px;
}

.mat-column-DateInMilliseconds {
    width: 30% !important;
}

.mat-column-Value {
    width: 30% !important;
}

.flash {
    animation-name           : flash;
    animation-duration       : 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction      : alternate;
    animation-play-state     : running;
}

@keyframes flash {
    from {
        color: red;
    }

    to {
        color: white;
    }
}