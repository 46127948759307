.parent-table-container {
	overflow: auto;
	height: calc(100%);
  }

  #regularMap {
	position: relative;
	width: 100%;
  }

  .card-header {
	min-height: 75px;
  }


  .asset-summary-left {
	  display: inline-block;
	  vertical-align: top;
	  height: 100%;
	  position: relative;
  }

  .asset-summary-right {
	  display: inline-block;
	  position: relative;
  }

  .centered-img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: 100%;
  }

