.quick-view-container {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // overflow: auto;
    height: 100%;
    width : 100%
}

.swiper {
    width : 100%;
    height: 100%;
}

.swiper-slide {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
}

.section-group {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    width         : 100%;
}


.linear-gauge-container {
    display   : block;
    text-align: center;
    cursor    : pointer;
}

.linear-gauge-section {
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.box-stat-content {
    display       : flex;
    flex-direction: column;
    border        : 1px solid #36454F;
    border-radius : 15px;
    text-align    : center;
    color         : white;
    font-family   : inherit;
    cursor        : pointer;
    height        : 100%;
    width         : 90%;
    margin        : 3px;
    padding       : 3px;
}

.stat-content {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: flex-start;
    gap            : 2px;
    text-align     : center;
    color          : white;
    font-family    : inherit;
    cursor         : pointer;
    height         : 100%;
    width          : 90%;
    margin         : 2px;
    padding        : 3px;
}

.stat-group {
    display              : grid;
    grid-template-columns: 50% 50%;
    // align-items: start;
    // height: 100%;
    // width: 85%;
    // margin: 4px;
    // padding: 4px;
}

.quick-view-unit-on {
    background-color: limegreen;
    color           : white;
    font-family     : 'Orbitron', sans-serif;
    font-weight     : 900;
    font-size       : small;
}

.quick-view-unit-off {
    background-color: darkgrey;
    color           : white;
    font-family     : 'Orbitron', sans-serif;
    font-weight     : 900;
    font-size       : small;
}

.progress-bar-styles-vertical {
    border: 1px solid #36454F !important;
    height: 100px !important;
    width : 20px !important;
}

.progress-bar-styles-horizontal {
    border: 1px solid #36454F !important;
    height: 15px !important;
    width : 80px !important;
}

.k-widget {
    border-color    : rgba(0, 0, 0, 0.00);
    color           : #fff;
    background-color: transparent;
}