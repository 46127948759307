/* width */
::-webkit-scrollbar {
	width: 12px;
	height: 12px;
	background-color: #f5f5f500;
}



/* Track */
::-webkit-scrollbar-track {
	border-radius:6px;
	-webkit-box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f500;
}

/* Track */
::-webkit-scrollbar-corner {
	color: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: #555;
}

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #b30000;
// }
