.white-content {
  .context-menu {
    background-color: $gray-100;
    padding: 4pt;
    font-size: 10pt;
    z-index: 1000;
    box-shadow: 0 0 12pt rgba(0, 0, 0, 0.25);
    border-radius: 4pt;
    padding: 0.5em 0 0.5em 0;
    animation: fadeIn 0.1s ease-out;
    opacity: 1;
    display: block;
  }

  .context-menu hr {
    border: none;
    border-bottom: 1px solid #eee;
  }

  .context-menu div {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: $gray-800;
    padding: 0.5em 2em 0.5em 0.75em;
    max-width: 18em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .context-menu div:hover {
    background-color: $gray-800;
    color: $white;
  }

  .context-menu div::before {
    content: "";
    float: left;
    margin-right: 0.75em;
    width: 0.5em;
    height: 1em;
    display: inline-block;
  }
}

.context-menu {
  background-color: $default;
  padding: 4pt;
  font-size: 10pt;
  z-index: 1000;
  box-shadow: 0 0 12pt rgba(0, 0, 0, 0.25);
  border-radius: 4pt;
  padding: 0.5em 0 0.5em 0;
  animation: fadeIn 0.1s ease-out;
  opacity: 1;
  display: block;
}

.context-menu hr {
  border: none;
  border-bottom: 1px solid $gray-300;
}

.context-menu div {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: $gray-200;
  padding: 0.5em 2em 0.5em 0.75em;
  max-width: 18em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.context-menu div:hover {
  background-color: $gray-800;
  color: $gray-100;
}

.context-menu div::before {
  content: "";
  float: left;
  margin-right: 0.75em;
  width: 0.5em;
  height: 1em;
  display: inline-block;
}

/* Animatinons */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.is-fadingIn {
  -webkit-animation: fadeIn 0.1s ease-out;
  animation: fadeIn 0.1s ease-out;
  opacity: 1;
  display: block;
}

.is-fadingOut {
  -webkit-animation: fadeOut 0.1s ease-out;
  animation: fadeOut 0.1s ease-out;
  opacity: 0;
  display: block;
}
