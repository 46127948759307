.mat-drawer-container, .mat-drawer-inner-container  {
    background-color: inherit !important;
}

mat-sidenav-content.mat-drawer-content.mat-sidenav-content {
    margin:0 !important
}

.mat-drawer-content {
    overflow: none !important;
}