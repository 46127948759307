$body-text-color-dark: #212427 !default;
$body-text-color-light: rgba(255, 255, 255, 0.7) !default;

$theme-colors: map-merge(
  (
    "default": $default,
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "white": $white,
    "neutral": $white,
    "dark": $dark,
    "darker": $darker,
    "body-text-color-light": $body-text-color-light,
    "body-text-color-dark": $body-text-color-dark,
  ),
  $theme-colors
);
