/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

.k-chart-tooltip-wrapper{
	  z-index: 99999 !important;
}

.k-popup{
	z-index: 99999 !important;
}

// mat-dialog-container {
//   background-color: rgb(39, 41, 61) !important;
// }

// .mat-tab-label,
// .mat-tab-link {
//   color: white;
// }

// .mat-tab-label {
//   color: white;
// }

// .mat-dialog-container {
//   padding: 0px !important;
// }

// .card {
//   overflow: hidden;
// }

// .mat-tab-body {
//   height: calc(100%);
// }
// .mat-tab-body-wrapper {
//   height: calc(100%);
// }

// .mat-tab-group {
//   height: calc(100%);
// }

// .card-body {
//   height: calc(100% - 36px);
//   padding-bottom: 10px !important;
// }

// table {
//   width: 100%;
// }

// mat-card {
//   background: none;
// }

// ::ng-deep .mat-form-field {
//   .mat-input-element {
//     color: rgba(255, 255, 255, 0.7);
//   }
//   .mat-form-field-label {
//     color: rgba(255, 255, 255, 0.7);
//   }
//   .mat-form-field-underline {
//     background-color: rgba(255, 255, 255, 0.7);
//   }
//   .mat-form-field-ripple {
//     background-color: rgba(255, 255, 255, 0.7);
//   }
//   .mat-form-field-required-marker {
//     color: rgba(255, 255, 255, 0.7);
//   }
// }

// ::ng-deep .mat-form-field.mat-focused {
//   .mat-form-field-label {
//     color: rgba(255, 255, 255, 0.7);
//   }
//   .mat-form-field-ripple {
//     background-color: rgba(255, 255, 255, 0.7);
//   }
//   .mat-form-field-required-marker {
//     color: rgba(255, 255, 255, 0.7);
//   }
//   .mat-input-element {
//     color: rgba(255, 255, 255, 0.7);
//   }
// }




