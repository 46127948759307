mat-sidenav {
    width: 220px;
    height: 100%;
    background: $card-black-background !important;
}
  
mat-sidenav-content {
    background: $card-black-background !important; 
    height: 100%;
    width: 100%;
    padding-left: 220px;
}

.mat-list {
    background-color: $card-black-background !important;
    text-align: center;

    .mat-list-item-content {
      display: initial !important;   
      padding: 0 !important;
    }
}