.btn :hover {
	// color: #607d8b;
	// background-color: #ddd;
	left: 0.1em;
}
.btn .selected {
	background-color: #cfd8dc;
	color: white;
}
.btn .selected:hover {
	background-color: #bbd8dc;
	color: white;
}

.jbt-btn {
	background-color: rgb(52, 70, 117);
	background-image: linear-gradient(to bottom left, #344675, #263148);
	border-color: none;
	border-width: 0px;
	border-radius: 0.3rem;
	line-height: 18.9px;
	padding-top: 0px;
	padding-bottom: 0px;
	color: darken($white, 6%);
	height: 43px;
	margin: 4px;
	// font-family: "Poppins", sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.jbt-btn-primary {
	background-color: rgb(26, 38, 90);
	background-image: linear-gradient(to bottom left, rgb(26, 38, 90), rgb(26, 38, 90));
}

.white-content {
	.jbt-btn {
		background-color: rgba(240, 240, 240, 1);
		background-image: linear-gradient(to bottom left, rgba(240, 240, 240, 1), rgb(216, 216, 216));
		border-color: none;
		border-radius: 0.3rem;
		border-width: 0px;
		line-height: 18.9px;
		padding-top: 0px;
		padding-bottom: 0px;
		height: 43px;
		color: #212427;
		// font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-weight: 600;
	}

	.jbt-btn-primary {
		background-color: darken($white, 15%) !important;
		background-image: linear-gradient(to bottom left, rgb(195, 195, 195), rgb(170, 170, 170));
	}
}

.jbt-btn-sm {
	background-color: rgb(52, 70, 117);
	background-image: linear-gradient(to bottom left, #344675, #263148);
	border-color: none;
	border-width: 0px;
	border-radius: 0.3rem;
	padding-top: 0px;
	padding-bottom: 0px;
	color: darken($white, 6%);
	height: 30px;
	margin: 4px;
	// font-family: "Poppins", sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.iops-btn-sm {
	background-color: rgb(52, 70, 117);
	background-image: linear-gradient(to bottom left, #344675, #263148);
	border-color: none;
	border-width: 0px;
	border-radius: 0.3rem;
	padding-top: 0px;
	padding-bottom: 0px;
	color: lighten($white, 6%);
	height: 30px;
	margin: 4px;
	// font-family: "Poppins", sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.jbt-btn-sm-primary {
	background-color: rgb(26, 38, 90);
	background-image: linear-gradient(to bottom left, rgb(26, 38, 90), rgb(26, 38, 90));
}

.white-content {
	.jbt-btn-sm {
		background-color: rgba(240, 240, 240, 1);
		background-image: linear-gradient(to bottom left, rgba(240, 240, 240, 1), rgb(216, 216, 216));
		border-color: none;
		border-radius: 0.3rem;
		border-width: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
		height: 30px;
		color: #212427;
		// font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-weight: 600;
	}
	.iops-btn-sm {
		background-color: rgba(240, 240, 240, 1);
		background-image: linear-gradient(to bottom left, rgba(240, 240, 240, 1), rgb(216, 216, 216));
		border-color: none;
		border-width: 0px;
		border-radius: 0.3rem;
		padding-top: 0px;
		padding-bottom: 0px;
		color: lighten($white, 6%);
		height: 30px;
		margin: 4px;
		// font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-weight: 600;
	}

	.jbt-btn-sm-primary {
		background-color: darken($white, 15%) !important;
		background-image: linear-gradient(to bottom left, rgb(195, 195, 195), rgb(170, 170, 170));
	}
}

.btn-color-selected {
	border: 3px solid white !important;
}

.white-content {
	.btn-color-selected {
		border: 3px solid red !important;
	}
}

.action-btn-link-icon {
	background-color: transparent !important;
	background      : unset !important;
	color           : white !important;
}

.danger-color {
	color: red;
}

.white-content {
	.action-btn-link-icon {
		color: #27304c !important;
	}
}
