.drop-area {
	cursor: pointer !important;
}

.white-content {
	.drop-area-hover {
		color: green !important;
	}
}

.drop-area-hover {
	color: lightgreen !important;
}

drop-area-field {
	min-height: 15px !important;
}

.view-file {
	cursor: pointer !important;
}