//dark content css here

.mat-expansion-panel {
  background: lighten($black, 3%);
}

.mat-expansion-panel-header-title {
  color: rgba(255, 255, 255, 0.8);
}
.mat-expansion-panel-header-description {
	color: rgba(255, 255, 255, 0.8);
  }

.mat-expansion-panel-content {
  color: rgba(255, 255, 255, 0.8);
}

.white-content {
  .mat-expansion-panel {
    background: #fff;
  }
  .mat-expansion-panel-header-title {
    color: rgba(0, 0, 0, 0.87);
  }
  .mat-expansion-panel-header-description {
    color: rgba(0, 0, 0, 0.87);
  }
  .mat-expansion-panel-content {
    color: rgba(0, 0, 0, 0.87);
  }
}
