.mat-option {
	// line-height: unset !important;
	height: unset !important;
	font-size: 14px !important;
	padding: 0 8px !important;
}

.mat-form-field {
	// width: 500px !important;
}

.mat-autocomplete-panel {
	background: $card-black-background;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background: #3f51b5;
}
