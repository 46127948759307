.file-upload-field {
    font-size: 0.7rem !important;
	width: 30px;
}

.file-upload-field-header {
	font-size  : 0.7rem !important;
	height     : 10px !important;
	width      : 30px !important;
	white-space: nowrap !important;
}
.chevron-up {
    top: 100%;
    transform: translateY(0px); 
    bottom: auto;
}

.progress-container .progress {
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
}

.mat-progres-bar-fill, .mat-progress-bar {
    box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
    border-radius: 3px;
    margin: 0 !important; 
    padding: 0 !important;
}

.object-contains-files {
    color: green !important;
}

.white-content {
    .object-contains-files {
        color: lightseagreen !important;
    }
}

.clipboard-larger {
    font-size: 1.2rem;
}