.main-panel {
  &,
  &[data="red"] {
    border-color: $danger;
  }

  .content {
    padding: 49px 30px 0px 250px;
    //padding: 45px 30px 15px 250px; -- next original changed this since most panels inside the layout were above the menu and losing the header without an element-specific padding-bottom:0px added.--Kirk T. Sherer, April 20, 2021.
    //padding: 80px 30px 30px 250px; -- original size of main panel (this is the one being used on the main website.) --Kirk T. Sherer, April 17, 2020. 
    min-height: calc(100vh - 34px);
  }
  .dashboard-area {
    padding: 0;
    min-height: calc(100vh - 34px);
  }
  .footer {
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 257px;
  }
}
@media screen and (max-width: 768px) {
  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.logo-img {
  width: 35px;
  height: 35px;
  display: block;
  margin-left: 2px;
  position: absolute;
  top: 12px;
  img {
    width: 35px;
  }
}
// .sidebar:before,
// .off-canvas-sidebar:before {
//   border-bottom: 5px solid $danger;
// }
// .sidebar,
// .off-canvas-sidebar {
//   &,
//   &[data="red"] {
//     @include linear-gradient($danger-states, $danger);
//   }
//   &[data="primary"] {
//     @include linear-gradient($primary-states, $primary);
//   }
// }
@media screen and (max-width: 991px) {
  .main-panel {
    .content {
      padding-left: 30px !important;
    }
  }
  .fixed-plugin .dropdown-toggle:after {
    display: none;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .nav-open .rtl .wrapper {
    transform: none;
  }
}
@media (min-width: 992px) {
  .sidebar-mini .main-panel .content,
  .sidebar-mini footer {
    padding-left: 100px !important;
  }
}
