.comm-loss-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 1000px;
    z-index: 30;
    background: #FFF;
    -ms-opacity: .7;
    opacity: .7;
    -ms-align-content: center;
    -webkit-align-content: center;
    align-content: center;
    vertical-align: middle;
    text-align: center;
    font-size: 3em;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.comm-loss-message {
    color: gray;
   -ms-opacity: 2;
   opacity: 2;
   z-index: 31;
}
