textarea.form-control {
    max-height: 500px !important;
	line-height: 1.6 !important;
}

textarea.form-control-large {
	max-height: 1000px !important;
	height: 1000px !important;
	max-width: 1800px !important;
	width: 1800px !important;
	line-height: 1.6 !important;
}

div.form-control-large {
	height: 1500px !important;
	max-width: 1900px !important;
	width: 1900px !important;
	line-height: 1.6 !important;
	overflow: auto;
}

.mat-form-field-infix {
    border-top: unset !important;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
    position: relative !important;
}
bs-datepicker-container,
bs-daterangepicker-container {
    // z-index: 99999 !important;
}

.dynamic-form {
    line-height: 0.5 !important;
    overflow-y : none !important;
}
