:host {
  display: block;
  position: relative;
  width: 100px;
  height: 50px;
}

/* Circle inside the slider button. Works with hidden checkboxes. */
.slider-switch {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 100;
  transition: left 0.3s;
}

/* Background of slider button */
.slider-text {
  overflow: hidden;
  background-color: #fc3164;
  border-radius: 25px;
  box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
  transition: background-color 0.3s;
  width: 60px;
  white-space: nowrap;
}

/* Text of slider button */

.slider-text-on {
  float: left;
  width: 30%;
  height: 100%;
  line-height: 30px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 0.7rem;
  color: #fff;
  text-align: center;
  padding-left: 5px;
}

.slider-text-off {
  float: left;
  width: 30%;
  height: 100%;
  line-height: 30px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 0.7rem;
  color: #fff;
  text-align: center;
  padding-left: 12px;
}

/* If the checkbox is checked, move the circle inside the slider button 52px to the right. */
input[type="checkbox"]:checked ~ .slider-switch {
  left: 30px;
}

/* Change the background color of the slider button if the box is masked */
input[type="checkbox"]:checked ~ .slider-text {
  background-color: #3dbf87;
}

//-- dynamic form settings for slider... it's a little different since the dynamic form is set up a specific way. --Kirk T. Sherer, June 5, 2020.
.dynamic-form-slider-switch {
  position: absolute;
  top: 2px;
  left: 17px;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 100;
  transition: left 0.3s;
}

.dynamic-form-slider-text {
  overflow: hidden;
  background-color: #fc3164;
  border-radius: 25px;
  box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
  transition: background-color 0.3s;
  width: 60px;
  white-space: nowrap;
}

/* If the checkbox is checked, move the circle inside the slider button 52px to the right. */
input[type="checkbox"]:checked ~ .dynamic-form-slider-switch {
  left: 45px;
}

/* Change the background color of the slider button if the box is masked */
input[type="checkbox"]:checked ~ .dynamic-form-slider-text {
  background-color: #3dbf87;
}

.jbt-slider-bar {
  display: none;
}
