gridster {
	min-height: 100px;
	height: calc(100vh - 50px);
	// // width: 100vw;
	// overflow-x: auto;
	background: none !important;
	.gridster-column {
		border: 0px !important;
	}
	.gridster-row {
		border: 0px !important;
	}
}

gridster-item {
	background-color: rgb(39, 41, 61) !important;
	border-radius: 5px;
}

.gridster-container {
	height: calc(100vh - 160px);
	overflow: hidden;
}

gridster .gridster-column,
gridster .gridster-row,
gridster-item,
gridster-item.gridster-item-resizing,
gridster-item.gridster-item-moving {
	transition: unset !important;
}

// gridster-item-inner {
//   position: relative;
//   width: calc(100% - 10px);
//   height: calc(100% - 10px);
//   margin: 5px;
// }
