.welcome {
	background-color: transparent;
	color: white;
}

.white-content {
	.welcome {
		color: black;
	}
}

.category-link {
	/* color: gray; */
	text-transform: lowercase;
}

.category-link::first-letter {
	text-transform: uppercase;
}

.category-link.active {
	/* color: red; */
	position: relative;
	text-transform: capitalize;
}

.category-link.active::before {
	content: '';
	height: .80em;
	width: .80em;
	background: #f44336;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	right: .5em;
	transform: translateY(-50%);
}

.updates {
	position: relative;
	overflow: visible;
}